<template>
  <section id="main">
    <b-navbar toggleable="lg" type="dark" variant="secondary" class="mb-2">
      <b-navbar-brand>Kebab Chefs! Save Game Editor (v1.0)</b-navbar-brand>
    </b-navbar>
    <b-container fluid>
      <save-game-editor />
    </b-container>
  </section>
</template>

<script>
import SaveGameEditor from './views/SaveGameEditor.vue'

export default {
  name: 'App',
  components: {
    SaveGameEditor
  }
}
</script>

<style>
</style>
