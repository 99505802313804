<template>
  <b-table-simple hover small caption-top responsive>
    <b-thead head-variant="dark">
      <b-tr>
        <b-th>Menu Item</b-th>
        <b-th>Is Unlocked</b-th>
        <b-th>Cooked Amount</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr
        v-for="(item, index) of localNames"
        :key="index"
      >
        <b-th>{{ item }}</b-th>
        <b-td>
          <b-form-checkbox
            v-model="localUnlocked[index]"
            :value="true"
            :unchecked-value="false"
            lazy
            @change="updateMenuData"
          >
            Recipe unlocked
          </b-form-checkbox>
        </b-td>
        <b-td>
          <b-form-input
            v-model="localCooked[index]"
            size="sm"
            number
            trim
            lazy
            @change="updateMenuData"
          />
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
export default {
  props: {
    names: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    cookedAmount: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    isUnlocked: {
      type: Array,
      default: () => ([]),
      required: true,
    }
  },
  data() {
    return {
      localNames: [],
      localCooked: [],
      localUnlocked: [],
    }
  },
  mounted() {
    this.localNames = this.names
    this.localCooked = this.cookedAmount
    this.localUnlocked = this.isUnlocked
  },
  watch: {
    names() {
      this.localNames = this.names
    },
    cookedAmount() {
      this.localCooked = this.cookedAmount
    },
    isUnlocked() {
      this.localUnlocked = this.isUnlocked
    },
  },
  methods: {
    updateMenuData() {
      this.$emit('update-json', this.localNames, this.localCooked, this.localUnlocked)
    }
  },
}
</script>

<style scoped></style>
