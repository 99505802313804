<template>
  <b-card>
    <b-card-title @click="visible = !visible">
      Select Save Game
      <b-icon
        :icon="visible ? 'chevron-down' : 'chevron-right'"
      />
    </b-card-title>
    <b-collapse id="savegame-collapse" v-model="visible">
      <b-form-group title="Select Save Game">
        <b-form-file
          v-model="file"
          :disabled="status.type === 'error'"
          :state="Boolean(file)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept=".save"
          @input="loadSaveGame"
        />
      </b-form-group>
    </b-collapse>
    <div
      class="mt-3"
      :class="status.type === 'error' ? 'text-danger' : 'text-success'"
    >
      {{  status.text }}
    </div>
    <div
      v-if="Boolean(file)"
      class="mt-3"
    >
      <b-button
        size="sm"
        variant="outline-success"
        block
        @click="$emit('download-savegame')"
      >
        Download modified savegame
      </b-button>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    saveData: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      visible: true,
      file: null,
      status: {
        type: 'success',
        text: '',
      },
    }
  },
  mounted() {
    if (typeof window.FileReader !== 'function') {
      this.status.type = 'error'
      this.status.text = 'The FileReader API is not supported on this browser.'
      return
    }
  },
  methods: {
    loadSaveGame() {
      const filereader = new FileReader();
      filereader.onload = e => {
        this.$emit('load', JSON.parse(e.target.result))
        this.$emit('filename', this.file.name)
        this.status.type = 'success'
        this.status.text = `Loaded savegame ${this.file.name}`
        this.visible = false
      }
      filereader.readAsText(this.file)
    },
  },
}
</script>

<style scoped></style>
