<template>
  <section id="bank-manager">
    <b-form-group label="Money">
      <b-form-input
        v-model="local.money"
        type="number"
        number
        trim
        min="0"
        step="1"
        lazy
        @change="emitUpdate"
      />
    </b-form-group>
    <b-form-group label="Credit/Loan Type">
      <b-form-radio
        v-model="local.creditID"
        name="creditID"
        :value="-1"
        lazy
        @change="emitUpdate"
      >
        None
      </b-form-radio>
      <b-form-radio
        v-model="local.creditID"
        name="creditID"
        :value="0"
        lazy
        @change="emitUpdate"
      >
        5,000.00 K$ Loan
      </b-form-radio>
      <b-form-radio
        v-model="local.creditID"
        name="creditID"
        :value="1"
        lazy
        @change="emitUpdate"
      >
        20,000.00 K$ Loan (with 10,000.00 K$ requirement)
      </b-form-radio>
      <b-form-radio
        v-model="local.creditID"
        name="creditID"
        :value="2"
        lazy
        @change="emitUpdate"
      >
        50,000.00 K$ Loan (with 25,000.00 K$ requirement)
      </b-form-radio>
    </b-form-group>
    <b-form-group label="Credit/Loan Remaining">
      <b-form-input
        v-model="local.creditAmount"
        type="number"
        number
        trim
        min="0"
        step="1"
        lazy
        @change="emitUpdate"
      />
    </b-form-group>
  </section>
</template>

<script>
export default {
  props: {
    money: {
      type: Number,
      default: () => 0.0,
      required: true,
    },
    creditID: {
      type: Number,
      default: () => 0,
      required: true,
    },
    creditAmount: {
      type: Number,
      default: () => 0,
      required: true,
    }
  },
  data() {
    return {
      local: {
        money: 0.0,
        creditID: 0,
        creditAmount: 0.0,
      }
    }
  },
  mounted() {
    this.local.money = this.money
    this.local.creditAmount = this.creditAmount
    this.local.creditID = this.creditID
  },
  watch: {
    money() { this.local.money = this.money },
    creditAmount() { this.local.creditAmount = this.creditAmount },
    creditID() { this.local.creditID = this.creditID },
  },
  methods: {
    emitUpdate() {
      this.$emit('update-json', this.local)
    }
  },
}
</script>

<style scoped></style>
