<template>
 <section id="quick-fixes">
    <b-form-group>
      <b-button
        :variant="unlocks.all ? 'success' : 'outline-info'"
        :disabled="unlocks.all"
        block
        @click="unlockAll"
      >
        {{ unlocks.all ? 'Unlocked' : 'Unlock' }} all
        <em>(Sets max level, experience, and unlocks all recipes & mastery levels)</em>
      </b-button>
    </b-form-group>
    <b-form-group>
      <b-button
        :variant="(unlocks.recipes || unlocks.all) ? 'success' : 'outline-info'"
        :disabled="(unlocks.recipes || unlocks.all)"
        block
        @click="unlockAllRecipes"
      >
        {{ (unlocks.recipes || unlocks.all) ? 'Unlocked' : 'Unlock' }} recipes
      </b-button>
    </b-form-group>
    <b-form-group>
      <b-button
        :variant="(unlocks.debt || unlocks.all) ? 'success' : 'outline-info'"
        :disabled="(unlocks.debt || unlocks.all)"
        block
        @click="clearAllDebt"
      >
        {{ (unlocks.debt || unlocks.all) ? 'Cleared' : 'Clear' }} all debt
      </b-button>
    </b-form-group>
    <b-form-group>
      <b-button
        :variant="(unlocks.bills || unlocks.all) ? 'success' : 'outline-info'"
        :disabled="(unlocks.bills || unlocks.all)"
        block
        @click="clearAllBills"
      >
        {{ (unlocks.bills || unlocks.all) ? 'Cleared' : 'Clear' }} all bills
      </b-button>
    </b-form-group>
    <pre>{{  JSON.stringify(savegame, null, 2) }}</pre>
  </section>
</template>

<script>
export default {
  props: {
    savegame: {
      type: Object,
      default: () => ({}),
      required: true,
    }
  },
  data() {
    return {
      unlocks: {
        recipes: false,
        debt: false,
        bills: false,
        all: false,
      },
      localSave: {}
    }
  },
  mounted() {
    this.localSave = this.savegame
  },
  watch: {
    savegame() { this.localSave = this.savegame }
  },
  methods: {
    unlockAll() {
      this.unlocks.all = true

      this.localSave.RestaurantLevel = 500
      this.localSave.CustomerSatisfaction = 1.0
      this.localSave.ExperiencePoint = 548813
      this.localSave.Money = 9999998.9

      this.localSave.perkIDs[0] = 3
      this.localSave.perkIDs[1] = 3
      this.localSave.perkIDs[2] = 3
      this.localSave.perkIDs[3] = 2
      this.localSave.perkIDs[4] = 3

      this.localSave.gourmetInfos = [
        {
          gRank: 0,
          State: 4,
          Amounts: [
            17,
            13,
            4
          ]
        },
        {
          gRank: 4,
          State: 4,
          Amounts: [
            80,
            58,
            75
          ]
        },
        {
          gRank: 2,
          State: 4,
          Amounts: [
            43,
            32,
            10
          ]
        },
        {
          gRank: 5,
          State: 5,
          Amounts: [
            0,
            0,
            0
          ]
        },
        {
          gRank: 3,
          State: 4,
          Amounts: [
            120,
            39,
            20
          ]
        },
        {
          gRank: 1,
          State: 4,
          Amounts: [
            26,
            15,
            8
          ]
        }
      ]

      this.SmugglerIntroduced = true
      this.SoupTutorialDone = true
      this.DessertTutorialDone = true
      this.Stage = 2

      this.clearAllBills(true)
      this.clearAllDebt(true)
      this.unlockAllRecipes(true)
    },
    clearAllBills(noop = false) {
      this.unlocks.bills = true
      this.localSave.billPrices = this.localSave.billPrices.map(() => 0)
      this.localSave.activeBills = this.localSave.activeBills.map(() => 0)
      if (!noop) this.update()
    },
    clearAllDebt(noop = false) {
      this.unlocks.debt = true
      this.localSave.creditID = -1
      this.localSave.creditAmount = 0
      if (!noop) this.update()
    },
    unlockAllRecipes(noop = false) {
      this.unlocks.recipes = true
      this.localSave.menuIsUnlocked = this.localSave.menuIsUnlocked.map(() => true)
      if (!noop) this.update()
    },
    update() {
      this.$emit('update-json', this.localSave)
    }
  },
}
</script>

<style scoped></style>
