<template>
  <section id="exp-manager">
    <b-form-group
      :label="`Restaurant Level: ${local.RestaurantLevel} / 500`"
    >
      <b-form-input
        v-model="local.RestaurantLevel"
        type="range"
        min="1"
        max="500"
        step="1"
        lazy
        @change="emitUpdate"
      />
    </b-form-group>
    <b-form-group
      :label="`Customer Satisfaction: ${local.CustomerSatisfaction}`"
    >
      <b-form-input
        v-model="local.CustomerSatisfaction"
        type="range"
        min="0.0"
        max="1.0"
        step="0.1"
        lazy
        @change="emitUpdate"
      />
    </b-form-group>
    <b-form-group
      :label="`Experience Points: ${local.ExperiencePoint} / 548813`"
    >
      <b-form-input
        v-model="local.ExperiencePoint"
        type="range"
        min="0"
        max="548813"
        step="1"
        lazy
        @change="emitUpdate"
      />
    </b-form-group>
  </section>
</template>

<script>
export default {
  props: {
    RestaurantLevel: {
      type: Number,
      default: () => 0.0,
      required: true,
    },
    CustomerSatisfaction: {
      type: Number,
      default: () => 0,
      required: true,
    },
    ExperiencePoint: {
      type: Number,
      default: () => 0,
      required: true,
    }
  },
  data() {
    return {
      local: {
        RestaurantLevel: 0,
        CustomerSatisfaction: 0.0,
        ExperiencePoint: 0,
      }
    }
  },
  mounted() {
    this.local.RestaurantLevel = this.RestaurantLevel
    this.local.CustomerSatisfaction = this.CustomerSatisfaction
    this.local.ExperiencePoint = this.ExperiencePoint
  },
  watch: {
    RestaurantLevel() { this.local.RestaurantLevel = this.RestaurantLevel },
    CustomerSatisfaction() { this.local.CustomerSatisfaction = this.CustomerSatisfaction },
    ExperiencePoint() { this.local.ExperiencePoint = this.ExperiencePoint },
  },
  methods: {
    emitUpdate() {
      this.$emit('update-json', {
        RestaurantLevel: Number(this.local.RestaurantLevel),
        CustomerSatisfaction: Number(this.local.CustomerSatisfaction),
        ExperiencePoint: Number(this.local.ExperiencePoint),
      })
    }
  },
}
</script>

<style scoped></style>
