<template>
  <b-alert variant="info">
    Coming soon...
  </b-alert>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    update() {
      // this.$emit('update-perks', this.localNames, this.localCooked, this.localUnlocked)
    }
  },
}
</script>

<style scoped></style>
