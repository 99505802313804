<template>
  <section id="gourmet-manager">
    <b-row>
      <b-col md="4">
        <b-card title="Spicy Juan [0]">
          <b-form-group label="Status">
            <b-form-select
              v-model="data.juan"
              :options="gourmetOptions"
              size="sm"
              lazy
              @change="emitUpdate"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card title="Tiny Tony [1]">
          <b-form-group label="Status">
            <b-form-select
              v-model="data.tony"
              :options="gourmetOptions"
              size="sm"
              lazy
              @change="emitUpdate"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card title="Elena [3]">
          <b-form-group label="Status">
            <b-form-select
              v-model="data.elena"
              :options="gourmetOptions"
              size="sm"
              lazy
              @change="emitUpdate"
            />
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="4">
        <b-card title="Monsieur Anton [4]">
          <b-form-group label="Status">
            <b-form-select
              v-model="data.anton"
              :options="gourmetOptions"
              size="sm"
              lazy
              @change="emitUpdate"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card title="Abby [5]">
          <b-form-group label="Status">
            <b-form-select
              v-model="data.abby"
              :options="gourmetOptions"
              size="sm"
              lazy
              @change="emitUpdate"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card title="Master [6]">
          <b-form-group label="Status">
            <b-form-select
              v-model="data.master"
              :options="gourmetOptions"
              size="sm"
              disabled
            />
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  props: {
    gourmet: {
      type: Array,
      default: () => ([]),
      required: true,
    },
  },
  data() {
    return {
      local: [],
      data: {
        juan: 0,
        tony: 0,
        elena: 0,
        anton: 0,
        abby: 0,
        master: 5,
      },
      gourmetOptions: [
        { value: 0, text: 'Locked' },
        { value: 1, text: 'Reachable' },
        { value: 2, text: 'Ready', disabled: true },
        { value: 3, text: 'Invited', disabled: true },
        { value: 4, text: 'Done' },
        { value: 5, text: 'Coming Soon', disabled: true },
      ]
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    gourmet() {
      this.getData()
    },
  },
  methods: {
    getData() {
      this.local = this.gourmet
      this.data.juan = this.local.find(v => v.gRank === 0).State
      this.data.tony = this.local.find(v => v.gRank === 1).State
      this.data.elena = this.local.find(v => v.gRank === 2).State
      this.data.anton = this.local.find(v => v.gRank === 3).State
      this.data.abby = this.local.find(v => v.gRank === 4).State
      this.data.master = this.local.find(v => v.gRank === 5).State
    },
    emitUpdate() {
      this.local.find(v => v.gRank === 0).State = this.data.juan
      this.local.find(v => v.gRank === 1).State = this.data.tony
      this.local.find(v => v.gRank === 2).State = this.data.elena
      this.local.find(v => v.gRank === 3).State = this.data.anton
      this.local.find(v => v.gRank === 4).State = this.data.abby
      this.local.find(v => v.gRank === 5).State = this.data.master
      this.$emit('update-json', this.local)
    }
  },
}
</script>

<style scoped></style>
