<template>
  <section>
    <b-row>
      <b-col md="12">
        <FileLoader
          @load="onSaveLoaded"
          @filename="setFilename"
          @download-savegame="downloadModifiedSave"
        />
      </b-col>
    </b-row>
    
    <b-card
      no-body
      class="mt-2"
    >
      <b-tabs
        v-if="saveGameLoaded"
        pills
        justified
        card
        content-class="mt-3"
      >
        <b-tab v-if="false" title="Quick Fix Panel">
          <quick-fix-panel
            :savegame="savegame"
            @update-json="updateQuick"
          />
        </b-tab>
        <b-tab title="Menu & Recipes">
          <menu-data-manager
            :names="savegame.menuDataNames"
            :cooked-amount="savegame.menuDataCookedAmount"
            :is-unlocked="savegame.menuIsUnlocked"
            @update-json="updateMenuData"
          />
        </b-tab>
        <b-tab title="Money & Banking">
          <bank-manager
            :money="savegame.Money"
            :creditID="savegame.creditID"
            :creditAmount="savegame.creditAmount"
            @update-json="updateBankData"
          />          
        </b-tab>
        <b-tab title="Experience & Satisfaction">
          <experience-manager
            :ExperiencePoint="savegame.ExperiencePoint"
            :CustomerSatisfaction="savegame.CustomerSatisfaction"
            :RestaurantLevel="savegame.RestaurantLevel"
            @update-json="updateExperienceData"
          />    
        </b-tab>
        <b-tab title="Perks (soon)" disabled>
          <perks-manager
            v-if="false"
            :perks="savegame.perkIDs"
            @update-json="updatePerks"
          />
        </b-tab>
        <b-tab title="Gourmets">
          <gourmet-manager
            :gourmet="savegame.gourmetInfos"
            @update-json="updateGourmet"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </section>
</template>

<script>
import FileLoader from '../components/FileLoader.vue'
import MenuDataManager from '../components/MenuDataManager.vue'
import BankManager from '../components/BankManager.vue'
import ExperienceManager from '../components/ExperienceManager.vue'
import QuickFixPanel from '../components/QuickFixPanel.vue'
import GourmetManager from '../components/GourmetManager.vue'
import PerksManager from '../components/PerksManager.vue'

export default {
  components: {
    FileLoader,
    BankManager,
    MenuDataManager,
    ExperienceManager,
    QuickFixPanel,
    GourmetManager,
    PerksManager,
  },
  data() {
    return {
      filename: '',
      savegame: {}
    }
  },
  computed: {
    saveGameLoaded() {
      return Object.keys(this.savegame).length > 0
    }
  },
  methods: {
    onSaveLoaded(data) {
      this.savegame = data
    },
    updateQuick(data) {
      this.savegame = data
    },
    updateMenuData(names, cooked, unlocked) {
      this.savegame.menuDataNames = names
      this.savegame.menuDataCookedAmount = cooked
      this.savegame.menuIsUnlocked = unlocked
      console.log("Menu & Recipes Updated")
    },
    updateExperienceData(data) {
      this.savegame.RestaurantLevel = data.RestaurantLevel
      this.savegame.CustomerSatisfaction = data.CustomerSatisfaction
      this.savegame.ExperiencePoint = data.ExperiencePoint
      console.log("Experience & Satisfaction Updated")      
    },
    updateBankData(data) {
      this.savegame.Money = data.money
      this.savegame.creditID = data.creditID
      this.savegame.creditAmount = data.creditAmount
      console.log("Money & Bank Updated")
    },
    updateGourmet(data) {
      this.savegame.gourmetInfos = data
      console.log("Gourmet Updated")
    },
    setFilename(name) {
      [this.filename] = name.split('.')
    },
    downloadModifiedSave() {
      const href = URL.createObjectURL(new Blob(["\ufeff",JSON.stringify(this.savegame)], { type: 'application/json;charset=utf-8;'}))
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `${this.filename} - modified.save`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }
  }
}
</script>

<style scoped></style>
